import React, { useState, useEffect, useRef} from "react";
import "../header/Header.css";
import "../footer/Footer.css";
import es_ES from '../lang/es_ES.json';
import Footer from "./Footer";
import Header from "../header/Header";
import { LanguageContext } from "../LanguageContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const ContactUs = (props) =>{
    const [language,setLanguage] = useState(()=>{
        let lang = JSON.parse(window.localStorage.getItem("lang"));
        return lang ? lang : es_ES;
    });
    useEffect(() => {
        const listenStorageChange = () => {
          if (localStorage.getItem("lang") === null) {
            setLanguage(es_ES);
          } else {
            setLanguage(JSON.parse(localStorage.getItem("lang")));
          }
        };
        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
      }, []);
    const name = useRef(null);
    const email = useRef(null);
    const message = useRef(null);

    const submit = ()=>{
      let nameInput = name.current.value;
      let emailInput = email.current.value;
      let messageInput = message.current.value;
      axios.post("https://bioideaz.aylemrm.com/mail/sendMail.php", { headers: {'X-Requested-With': 'XMLHttpRequest'},data:{name:nameInput,email:emailInput,message:messageInput}})
      .then((resp)=>{
        let type = resp.data.type;
        let mssg = resp.data.message;
        if(type.toString()==="success"){
          toast.success(mssg);
        }else if(type.toString()==="error"){
          toast.error(mssg);
        }else{
          toast.error("undefined error");
        }
      })
    }
    return (
        <div className="App">
            <LanguageContext.Provider value={language}>
              <ToastContainer/>
                <Header language={language}/>
                <div className="containerContact">
                  <div className="titlecontact">{language.contactenos}</div>
                  <form id="form" action="/en/contacto" method="post">
                      <div className="containerForm">
                          <div className="optionsForm">
                              <p>{language.form_name}:</p>
                              <input ref={name} type="text" name="name" id="name"/>
                          </div>
                          <div className="optionsForm">
                              <p>{language.form_email}:</p>
                              <input ref={email} type="text" name="email" id="email"/>
                          </div>
                          <div className="errorAlert" id="errorEmail"></div>
                          <div className="optionsFormMessaje">
                              <p>{language.form_message}:</p>
                              <textarea ref={message} name="message" id="mensage" cols="30" rows="10"></textarea>
                          </div>
                          <div className="errorAlert" id="errorPassword"></div>
                          <div className="contentSend">
                              <div className="buttonSend">    
                                  <div className="sendsDiv" onClick={()=>{submit()}}>{language.form_send}</div>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
                <Footer language={language} addclassName=""/>
            </LanguageContext.Provider>
        </div>
    );
}