import React, { useContext } from "react";
import "./Main.css";
import { LanguageContext } from '../LanguageContext';

export default function Main(props) {
  const { language} = useContext(LanguageContext);
  return (
      <div className="main">
        <h1>{language.page_1_content}</h1>
        <div className="imgContentMain">
          <img src="./images/content_banner1.png" alt="content banner"/>
        </div>
        <div className="containerInfoMain">
        <div className="franjaServicesMain">
          <div className="contentInfo">
              <div className="textInfo">
                <div className="imgKimia desktop">
                    <img src="./images/Portada_KIMIA.jpg" alt="kimia" className="imgKimia"/>
                </div>
                <div className="descriptionServiceMain">
                  <div className="titleSecund">
                      <h3>{language.page_1_content2_title}</h3>
                  </div>
                    <div className="imgKimia mobile">
                        <img src="./images/Portada_KIMIA.jpg" alt="kimia" className="imgKimia"/>
                    </div>
                    <p>{language.page_1_content2}</p>
                </div>
                <div className="imgKimiaPerson">
                    <img src="./images/Boceto_color_1_b.png " alt="character" />
                </div>
              </div>
            </div>
        </div>
    </div>
      </div>
  );
}
