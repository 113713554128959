import React from "react";
import "../footer/Footer.css";

export default function Footer(props) {
  return (
    <div className={`footer ${props.addClass}`}>
      <h1>{props.language.page_1_footer}</h1>
    </div>
  );
}
