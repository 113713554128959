import React, { useState, useEffect} from "react";
import "../header/Header.css";
import "../footer/Footer.css";
import "./styles.css";
import es_ES from '../lang/es_ES.json';
import Footer from "./Footer";
import Header from "../header/Header";
import { LanguageContext } from "../LanguageContext";

export const AboutUs = (props) =>{
    const [language,setLanguage] = useState(()=>{
        let lang = JSON.parse(window.localStorage.getItem("lang"));
        return lang ? lang : es_ES;
    });
    useEffect(() => {
        const listenStorageChange = () => {
          if (localStorage.getItem("lang") === null) {
            setLanguage(es_ES);
          } else {
            setLanguage(JSON.parse(localStorage.getItem("lang")));
          }
        };
        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
      }, []);
    return (
        <div className="App">
            <LanguageContext.Provider value={language}>
                <Header language={language}/>
                <div className="titleUs">
                    <h3>{language.aboutus_title}</h3>
                </div>
                <section className="containerUS">
                    <div className="contentUs">
                        <div className="usText">
                            <h3>{language.aboutus_content_title}</h3>
                            <p>
                            {language.aboutus_content}
                            </p>
                        </div>
                    
                    </div>
                    <div className="imgUs">
                        <img src="../images/aboutus/simbolo.png" alt=""/>
                    </div>
                </section>
                <div className="descriptionUs">
                  <div className="titleUsWhite">
                      <h3>{language.aboutus_description_title}</h3>
                      <p>{language.aboutus_description_content}</p>
                  </div>
                </div>
                <section className="containerUS">
                  <div className="leftImgUs">
                        <img src="../images/aboutus/simbolo2.png" alt=""/>
                  </div>
                  <div className="leftContentUs">
                      <div className="leftUsText">
                          <div>
                            <h3>{language.aboutus_description_content_1_title1}</h3>
                            <h3>{language.aboutus_description_content_1_title2}</h3>
                          </div>
                          <p>
                              {language.aboutus_description_content_1}
                          </p>
                      </div>
                  </div>  
                </section>
                <section className="containerUS">
                  <div className="rightContentUs">
                      <div className="rightUsText">
                          <div>
                            <h3>{language.aboutus_description_content_2_title1}</h3>
                            <h3>{language.aboutus_description_content_2_title2}</h3>
                          </div>
                          <p>
                          {language.aboutus_description_content_2}
                          </p>
                      </div>
                  </div>
                  <div className="rightImgUs">
                        <img src="../images/aboutus/simbolo3.png" alt=""/>
                  </div>
                </section>
                <section className="containerUS">
                  <div className="leftImgUs">
                        <img src="../images/aboutus/simbolo4.png" alt=""/>
                  </div>
                  <div className="leftContentUs">
                      <div className="leftUsText">
                          <div>
                            <h3>{language.aboutus_description_content_3_title1}</h3>
                            <h3>{language.aboutus_description_content_3_title2}</h3>
                          </div>
                          <p>
                          {language.aboutus_description_content_3}
                          </p>
                      </div>
                  </div>  
                </section>
                <section className="containerUS">
                  <div className="rightContentUs">
                      <div className="rightUsText">
                          <div>
                            <h3>{language.aboutus_description_content_4_title1}</h3>
                            <h3>{language.aboutus_description_content_4_title2}</h3>
                          </div>
                          <p>
                            {language.aboutus_description_content_4}
                          </p>
                      </div>
                  </div>
                  <div className="rightImgUs">
                        <img src="../images/aboutus/simbolo5.png" alt=""/>
                  </div>
                </section>

                <section className="containerAwards">
                    <div className="elipse">
                        <div className="InfoElipse">
                            <div className="titleAwards">
                                <h3>{language.aboutus_award_title}</h3>
                            </div>
                            <div className="textAwards">
                                <p>
                                {language.aboutus_award_content}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer language={language} addClass="color"/>
            </LanguageContext.Provider>
        </div>
    );
}