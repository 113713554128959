import React, { useContext } from "react";
import "./Footer.css";
import { LanguageContext } from '../LanguageContext';

export default function Footer(props) {
  const { language } = useContext(LanguageContext);
  
    return (
      <div className={`footer`}>
        <h1>{language.page_1_footer}</h1>
      </div>
    );
}
