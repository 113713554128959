import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import es_ES from "../lang/es_ES.json";
import en_EN from "../lang/en_EN.json";
import { LanguageContext } from '../LanguageContext';
import { Link } from "react-router-dom";

export default function Header(props) {
  const { language, setLanguage } = useContext(LanguageContext);
  const [relativeRef, setRelativeRef] = useState("");

  useEffect(()=>{
    if (window.localStorage.getItem("lang") === "") {
      setLanguage(es_ES);
    } else {
      if(window.location.pathname==="/"){
        setLanguage(JSON.parse(window.localStorage.getItem("lang")));
      }
    }
    if(window.location.pathname.split("/").length > 2){
      setRelativeRef("../../");
    }else{
      setRelativeRef("");
    }
  },[]);

  const changeLanguage = (lang)=>{
    localStorage.setItem("lang", JSON.stringify(lang));
    window.dispatchEvent(new Event("storage"));
    if(window.location.pathname==="/"){
      setLanguage(lang);
    }
  }
  return (
    <div className="containerHeader">
      <div className="imgHeader">
      <Link to="/" className="link"><img src={`${relativeRef}images/Logo.png`} alt="logo"/></Link>
      </div>
      <div className="navbar">
        <div><Link to="/productions" className="link">{props.language?props.language.producciones.toUpperCase():language.producciones.toUpperCase()}</Link></div>
        <div><Link to="/services" className="link">{props.language?props.language.servicios.toUpperCase():language.servicios.toUpperCase()}</Link></div>
        <div><Link to="/aboutUs" className="link">{props.language?props.language.nosotros.toUpperCase():language.nosotros.toUpperCase()}</Link></div>
        <div><Link to="/gallery" className="link">{props.language?props.language.galeria.toUpperCase():language.galeria.toUpperCase()}</Link></div>
        <div><Link to="/contactUs" className="link">{props.language?props.language.contactenos.toUpperCase():language.contactenos.toUpperCase()}</Link></div>
      </div>
      <div className="optionsLanguage">
        <div className="content">
          <div className="lang" onClick={()=>changeLanguage(es_ES)}>
            <img src={`${relativeRef}images/Gift-Banderas-Espanol.gif`} alt={props.language?props.language.espanol:language.espanol}/>
            <p>{props.language?props.language.espanol:language.espanol}</p>
          </div>
          <div className="lang" onClick={()=>changeLanguage(en_EN)}>
            <img src={`${relativeRef}images/Gift-Banderas-Ingles.gif`} alt={props.language?props.language.ingles:language.ingles}/>
            <p>{props.language?props.language.ingles:language.ingles}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
